* { box-sizing:border-box; }

.containerFrontBannner{
    display: flex;
    justify-content: center;  
    align-content: center;
    flex-direction: row;

}

.containerFronth1{
    color: #FF3B3F;
}
.containerFrontimg {
    padding-top: 66px;
    width: 100%;
    height: 50vh;
    align-self: center;
}

@media screen and (min-width: 768px) {
    .containerFrontimg {
        width: max-content;
        height: max-content;
    }
}

@media screen and (min-width: 1500px) {
    .containerFrontimg {
        padding-top: 0px;
        width: 65vw;
        height: 100vh;
    }
}