.containerDrycleaning{
    background: #ffffff ;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: nowrap;
    color: #2F3676;
    font-family: 'Roboto';
}

.containerDrycleaningRow{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    padding-right: 5%;
}

.containerDrycleaningPrice{
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    color: #2F3676;
}
.containerDrycleaningPrice h1{
    font-weight: bold;
    color: #007aff;
    font-size: 1.3rem;
}

.bob2{
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
    flex-direction: column;
}

.bob{
    display: flex;
    justify-content: center;
    flex-direction: row;
}

.coatImage{
    padding-top: 80px;
    max-width: 30vh;
    max-height: 35vh;
}

.DryCleaningPricePadding {
    flex-basis: 32%;
}

.DryCleaningPricePadding p{
    font-weight: bold;
}

.containerDrycleaning p{
    font-size: 12px;
    color:#2F3676;
    font-weight: bold;
}

.containerDrycleaning h2{
    font-size: 1.3em;
    font-weight: bold;
}

.containerRow{
    display: flex;
    flex-direction:  row;
    justify-content: center;
}

.containerDrycleaning button{
    background:#2F3676;
    color:#fff;
    border:none;
    position:relative;
    height:40px;
    font-size:0.91em;
    padding:0 2em;
    cursor:pointer;
    transition:800ms ease all;
    outline:none;
    font-family: 'Roboto';
  	border-radius: 10rem;
  }

  button:hover{
    background:#fff;
    color:#2F3676;
    font-family: 'Roboto';
  }

  button:before,button:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #2F3676;
    transition:400ms ease all;
  }

  button:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }

  button:hover:before,button:hover:after{
    width:100%;
    transition:800ms ease all;
}

@media screen and (min-width: 768px) {
    .containerDrycleaning{
        width: 100%;
    }

    .DryCleaningPricePadding{
    flex-basis: 20%;
    flex-basis: 300px;
    }   

    .containerDrycleaning button{
        height:55px;
        font-size:2.5em;
    }
    .containerDrycleaning h2{
        font-size: 3em;
    }
    
    .containerDrycleaning p{
        font-size: 20px;
    }
    
    .coatImage{
        padding-top: 0px;
        max-width: 40vh;
        max-height: 40vh;
    }
}