.containerAlterations{
    background: #eefafa ;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    color: #007aff;

}
    
.alterationsImage{
    width: 50vh;
    height: 40vh;
}

.containerText{
    font-weight: bold;
    padding-right: 5%;

}
.containerText h1{
    text-align: center;
    font-size: 1.2em;
    color: #007aff;
}

.containerText p{
    padding-left: 6%;
    font-size: 12px;
    color: #15B7B9;
}

@media screen and (min-width: 768px) {
    .alterationsImage{
        width: 100vh;
        height: 100vh;
    }
    .containerText h1{
        font-size: 2em;
    }
    
    .containerText p{
        font-size: 18px;
    }
}
