.navbar {
    font-size: 18px;
    background-color: white;
    padding-bottom: 10px;
    font-family: 'Roboto';
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index:1000;
  }
  
.main_nav_none{
    list-style-type: none;
    display: block;
}

.navbar_toggle {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
}

.main_nav{
    list-style-type: none;
    display: none;
    height: 60px;

  }
  
.nav_links,
.logo {
    text-decoration: none;
    color: #2F3676;
    font-size: 15px;
    font-weight: bold;
}
 
.logoImage {
  max-width: 45px;
  max-height: 45px;
  padding-right: 5px;
}

.main_nav li {
    text-align: center;
    margin: 15px auto;
}
  
.logo{
    display: flex;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 20px;
}
  
@media screen and (min-width: 768px) {
    .navbar {
      display: flex;
      justify-content: space-between;
      height: 75px;
      padding-bottom: 70px;
      align-items: center;
    }

    .main_nav {
      display: flex;
      margin-right: 30px;
      flex-direction: row;
      justify-content: flex-end;
    }

    .main_nav_none{
        display: flex;
        margin-right: 30px;
        flex-direction: row;
        justify-content: flex-end;
        height: 60px;
    }
    
    .nav_home{
      justify-content: flex-start;
      color:white;
    }
    
    .main_nav li {
      margin: 0;
    }
    
    .nav_links {
      margin-left: 40px;
      font-size: 24px;
    }
  
    .logo {
      margin-top: 0;
      font-size: 24px;
      padding-top: 65px;
    }
  
    .navbar_toggle {
      display: none;
    }
  
}