/* Flexible iFrame (for google maps)*/

.FlexibleContainer {
    position: relative;
    padding-bottom: 95%;
    height: 0;
    overflow: hidden;
    display: block;
}



@media screen and (min-width: 768px) {
    .FlexibleContainer {
        padding-bottom: 30%;
    }
}
