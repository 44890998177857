.root{
    background: #eefafa ;
}

.containerReviews{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #2F3676;
    font-family: 'Roboto';

}

.card{
    flex-direction: row;
    background-color: white;
    width: 32vh;
    height: 25vh;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.arrowLeft {
    border-right: 2px solid; 
    border-bottom: 2px solid;
    height: 20px;
    width: 20px;
    transform: rotate(140deg);
    align-self: center;
    color: #2F3676;
}

.arrowRight {
    border-right: 2px solid; 
    border-bottom: 2px solid;
    height: 20px;
    width: 20px;
    transform: rotate(-45deg);
    align-self: center;
    color: #2F3676;

}

.titleReview{
    padding: 10px;
    text-align: center;
    color: #2F3676;
    position: relative;
    font-size: 1.2em;
}

.borderBox p{
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  background-color: white;
  word-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 768px) {
  .arrowLeft {
      border-right: 5px solid; 
      border-bottom: 5px solid;
      height: 40px;
      width: 40px;
  }

  .arrowRight {
      border-right: 5px solid; 
      border-bottom: 5px solid;
      height: 40px;
      width: 40px;
  }

  .borderBox h2{
    font-size: 2em;
  }

  .borderBox p{
    font-size: 1.3em;
  }
  
  .title{
      padding: 15px;
      font-size: 2em;
      line-height: 1.4em;
  }

  .titleReview{
    font-size: 3.5em;
  }

  .card{
      width: 95vh;
      height: 55vh;
  }
}

@media screen and (min-width: 1500px) {
  .card{
      width: 55vh;
      height: 35vh;
  }
}
