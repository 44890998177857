.containerContactUs{
    background: #eefafa ;
}

.hoursb {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hoursbox {
  min-width: 25em;
  background: #fff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.hours {
  display: flex;
  justify-content: space-between;
}

ul {
  margin: 0;
  padding: 5px;
  list-style: none;
}

ul li {
  color: #777;
  font-size: 0.8em;
}

ul:first-child li:first-child {
  font-weight: bold;
  color:#007aff ;
  font-size: 0.9em;
}

hr {
  margin: 5px 30px 25px;
}

h1 {
  margin: 30px;
  font-size: 0.9em;
  color: #007aff;
}

.location{
  padding-left: 40px;
  padding-top: 5px;
  color: #777;
}


.location p{
  font-size: 0.8em;
  padding-bottom: 30%;
}

.location p:first-child{
  color:#007aff;
  font-weight: bold;
  font-size: 0.9em;
}

.isOpen h1{
    font-size: 1em;
}

@media screen and (min-width: 768px) {
  .containerContactUs{
    padding-top: 100px;
  }

  .hoursb h2{
    font-size: 2.4em;
  }

  ul:first-child li:first-child {
    font-weight: bold;
    color:#007aff ;
    font-size: 1.4em;
  }

  ul {
    margin: 0;
    padding: 40px;
    list-style: none;
  }

  ul li {
    color: #777;
    font-size: 1.15em;
  }   

  hr {
    margin: 5px 50px 25px;
  }


  .location{
    padding-left: 40px;
    padding-top: 5px;
    color: #777;
  }

  .location p:first-child{
    color:#007aff;
    font-weight: bold;
    font-size: 1.9em;
  }

  .isOpen h1{
    font-size: 1.45em;
}

}

@media screen and (min-width: 1500) {
  .containerContactUs{
    background: #eefafa ;
    padding-top: 0px;
  }
}