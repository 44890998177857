body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
  font-family: 'Roboto';
}





.Navbar_navbar__1C2Dr {
    font-size: 18px;
    background-color: white;
    padding-bottom: 10px;
    font-family: 'Roboto';
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index:1000;
  }
  
.Navbar_main_nav_none__33CHb{
    list-style-type: none;
    display: block;
}

.Navbar_navbar_toggle__2ZoCs {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.8);
    font-size: 24px;
}

.Navbar_main_nav__1raFh{
    list-style-type: none;
    display: none;
    height: 60px;

  }
  
.Navbar_nav_links__2ymQ3,
.Navbar_logo__2Wrdm {
    text-decoration: none;
    color: #2F3676;
    font-size: 15px;
    font-weight: bold;
}
 
.Navbar_logoImage__1ga7_ {
  max-width: 45px;
  max-height: 45px;
  padding-right: 5px;
}

.Navbar_main_nav__1raFh li {
    text-align: center;
    margin: 15px auto;
}
  
.Navbar_logo__2Wrdm{
    display: -webkit-flex;
    display: flex;
    font-size: 15px;
    margin-top: 10px;
    margin-left: 20px;
}
  
@media screen and (min-width: 768px) {
    .Navbar_navbar__1C2Dr {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
              justify-content: space-between;
      height: 75px;
      padding-bottom: 70px;
      -webkit-align-items: center;
              align-items: center;
    }

    .Navbar_main_nav__1raFh {
      display: -webkit-flex;
      display: flex;
      margin-right: 30px;
      -webkit-flex-direction: row;
              flex-direction: row;
      -webkit-justify-content: flex-end;
              justify-content: flex-end;
    }

    .Navbar_main_nav_none__33CHb{
        display: -webkit-flex;
        display: flex;
        margin-right: 30px;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: flex-end;
                justify-content: flex-end;
        height: 60px;
    }
    
    .Navbar_nav_home__2LJyU{
      -webkit-justify-content: flex-start;
              justify-content: flex-start;
      color:white;
    }
    
    .Navbar_main_nav__1raFh li {
      margin: 0;
    }
    
    .Navbar_nav_links__2ymQ3 {
      margin-left: 40px;
      font-size: 24px;
    }
  
    .Navbar_logo__2Wrdm {
      margin-top: 0;
      font-size: 24px;
      padding-top: 65px;
    }
  
    .Navbar_navbar_toggle__2ZoCs {
      display: none;
    }
  
}
* { box-sizing:border-box; }

.FrontBanner_containerFrontBannner__2b4M7{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;  
    -webkit-align-content: center;  
            align-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;

}

.FrontBanner_containerFronth1__3fsbH{
    color: #FF3B3F;
}
.FrontBanner_containerFrontimg__3YD3b {
    padding-top: 66px;
    width: 100%;
    height: 50vh;
    -webkit-align-self: center;
            align-self: center;
}

@media screen and (min-width: 768px) {
    .FrontBanner_containerFrontimg__3YD3b {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        height: -webkit-max-content;
        height: -moz-max-content;
        height: max-content;
    }
}

@media screen and (min-width: 1500px) {
    .FrontBanner_containerFrontimg__3YD3b {
        padding-top: 0px;
        width: 65vw;
        height: 100vh;
    }
}
.Drycleaning_containerDrycleaning__1QFoJ{
    background: #ffffff ;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    color: #2F3676;
    font-family: 'Roboto';
}

.Drycleaning_containerDrycleaningRow__2I7cD{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
    padding-right: 5%;
}

.Drycleaning_containerDrycleaningPrice__1Zg4W{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color: #2F3676;
}
.Drycleaning_containerDrycleaningPrice__1Zg4W h1{
    font-weight: bold;
    color: #007aff;
    font-size: 1.3rem;
}

.Drycleaning_bob2__9pl7-{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    padding-top: 10px;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.Drycleaning_bob__lXKPv{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-direction: row;
            flex-direction: row;
}

.Drycleaning_coatImage__3f5lv{
    padding-top: 80px;
    max-width: 30vh;
    max-height: 35vh;
}

.Drycleaning_DryCleaningPricePadding__35LD3 {
    -webkit-flex-basis: 32%;
            flex-basis: 32%;
}

.Drycleaning_DryCleaningPricePadding__35LD3 p{
    font-weight: bold;
}

.Drycleaning_containerDrycleaning__1QFoJ p{
    font-size: 12px;
    color:#2F3676;
    font-weight: bold;
}

.Drycleaning_containerDrycleaning__1QFoJ h2{
    font-size: 1.3em;
    font-weight: bold;
}

.Drycleaning_containerRow__3jWp-{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction:  row;
            flex-direction:  row;
    -webkit-justify-content: center;
            justify-content: center;
}

.Drycleaning_containerDrycleaning__1QFoJ button{
    background:#2F3676;
    color:#fff;
    border:none;
    position:relative;
    height:40px;
    font-size:0.91em;
    padding:0 2em;
    cursor:pointer;
    -webkit-transition:800ms ease all;
    transition:800ms ease all;
    outline:none;
    font-family: 'Roboto';
  	border-radius: 10rem;
  }

  button:hover{
    background:#fff;
    color:#2F3676;
    font-family: 'Roboto';
  }

  button:before,button:after{
    content:'';
    position:absolute;
    top:0;
    right:0;
    height:2px;
    width:0;
    background: #2F3676;
    -webkit-transition:400ms ease all;
    transition:400ms ease all;
  }

  button:after{
    right:inherit;
    top:inherit;
    left:0;
    bottom:0;
  }

  button:hover:before,button:hover:after{
    width:100%;
    -webkit-transition:800ms ease all;
    transition:800ms ease all;
}

@media screen and (min-width: 768px) {
    .Drycleaning_containerDrycleaning__1QFoJ{
        width: 100%;
    }

    .Drycleaning_DryCleaningPricePadding__35LD3{
    -webkit-flex-basis: 20%;
            flex-basis: 20%;
    -webkit-flex-basis: 300px;
            flex-basis: 300px;
    }   

    .Drycleaning_containerDrycleaning__1QFoJ button{
        height:55px;
        font-size:2.5em;
    }
    .Drycleaning_containerDrycleaning__1QFoJ h2{
        font-size: 3em;
    }
    
    .Drycleaning_containerDrycleaning__1QFoJ p{
        font-size: 20px;
    }
    
    .Drycleaning_coatImage__3f5lv{
        padding-top: 0px;
        max-width: 40vh;
        max-height: 40vh;
    }
}
.Alterations_containerAlterations__1L9gd{
    background: #eefafa ;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color: #007aff;

}
    
.Alterations_alterationsImage__1p70g{
    width: 50vh;
    height: 40vh;
}

.Alterations_containerText__1n-Vt{
    font-weight: bold;
    padding-right: 5%;

}
.Alterations_containerText__1n-Vt h1{
    text-align: center;
    font-size: 1.2em;
    color: #007aff;
}

.Alterations_containerText__1n-Vt p{
    padding-left: 6%;
    font-size: 12px;
    color: #15B7B9;
}

@media screen and (min-width: 768px) {
    .Alterations_alterationsImage__1p70g{
        width: 100vh;
        height: 100vh;
    }
    .Alterations_containerText__1n-Vt h1{
        font-size: 2em;
    }
    
    .Alterations_containerText__1n-Vt p{
        font-size: 18px;
    }
}

.Reviews_root__183ZP{
    background: #eefafa ;
}

.Reviews_containerReviews__KHx-D{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    color: #2F3676;
    font-family: 'Roboto';

}

.Reviews_card__2INR-{
    -webkit-flex-direction: row;
            flex-direction: row;
    background-color: white;
    width: 32vh;
    height: 25vh;
    box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
}

.Reviews_arrowLeft__31_DS {
    border-right: 2px solid; 
    border-bottom: 2px solid;
    height: 20px;
    width: 20px;
    -webkit-transform: rotate(140deg);
            transform: rotate(140deg);
    -webkit-align-self: center;
            align-self: center;
    color: #2F3676;
}

.Reviews_arrowRight__3Z9C2 {
    border-right: 2px solid; 
    border-bottom: 2px solid;
    height: 20px;
    width: 20px;
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    -webkit-align-self: center;
            align-self: center;
    color: #2F3676;

}

.Reviews_titleReview__6IP-K{
    padding: 10px;
    text-align: center;
    color: #2F3676;
    position: relative;
    font-size: 1.2em;
}

.Reviews_borderBox__3fTvH p{
  font-size: 0.7em;
  font-weight: bold;
  text-align: center;
  background-color: white;
  word-wrap: break-word;
  padding-left: 5px;
  padding-right: 5px;
}

@media screen and (min-width: 768px) {
  .Reviews_arrowLeft__31_DS {
      border-right: 5px solid; 
      border-bottom: 5px solid;
      height: 40px;
      width: 40px;
  }

  .Reviews_arrowRight__3Z9C2 {
      border-right: 5px solid; 
      border-bottom: 5px solid;
      height: 40px;
      width: 40px;
  }

  .Reviews_borderBox__3fTvH h2{
    font-size: 2em;
  }

  .Reviews_borderBox__3fTvH p{
    font-size: 1.3em;
  }
  
  .Reviews_title__uZklD{
      padding: 15px;
      font-size: 2em;
      line-height: 1.4em;
  }

  .Reviews_titleReview__6IP-K{
    font-size: 3.5em;
  }

  .Reviews_card__2INR-{
      width: 95vh;
      height: 55vh;
  }
}

@media screen and (min-width: 1500px) {
  .Reviews_card__2INR-{
      width: 55vh;
      height: 35vh;
  }
}

.contactUs_containerContactUs__3H0Hr{
    background: #eefafa ;
}

.contactUs_hoursb__1X4rI {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}

.contactUs_hoursbox__16SOr {
  min-width: 25em;
  background: #fff;
  box-shadow: 2px 2px 2px 0 rgba(0, 0, 0, 0.2);
  padding: 1em;
}

.contactUs_hours__18tHw {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

ul {
  margin: 0;
  padding: 5px;
  list-style: none;
}

ul li {
  color: #777;
  font-size: 0.8em;
}

ul:first-child li:first-child {
  font-weight: bold;
  color:#007aff ;
  font-size: 0.9em;
}

hr {
  margin: 5px 30px 25px;
}

h1 {
  margin: 30px;
  font-size: 0.9em;
  color: #007aff;
}

.contactUs_location__3wsft{
  padding-left: 40px;
  padding-top: 5px;
  color: #777;
}


.contactUs_location__3wsft p{
  font-size: 0.8em;
  padding-bottom: 30%;
}

.contactUs_location__3wsft p:first-child{
  color:#007aff;
  font-weight: bold;
  font-size: 0.9em;
}

.contactUs_isOpen__3jASr h1{
    font-size: 1em;
}

@media screen and (min-width: 768px) {
  .contactUs_containerContactUs__3H0Hr{
    padding-top: 100px;
  }

  .contactUs_hoursb__1X4rI h2{
    font-size: 2.4em;
  }

  ul:first-child li:first-child {
    font-weight: bold;
    color:#007aff ;
    font-size: 1.4em;
  }

  ul {
    margin: 0;
    padding: 40px;
    list-style: none;
  }

  ul li {
    color: #777;
    font-size: 1.15em;
  }   

  hr {
    margin: 5px 50px 25px;
  }


  .contactUs_location__3wsft{
    padding-left: 40px;
    padding-top: 5px;
    color: #777;
  }

  .contactUs_location__3wsft p:first-child{
    color:#007aff;
    font-weight: bold;
    font-size: 1.9em;
  }

  .contactUs_isOpen__3jASr h1{
    font-size: 1.45em;
}

}

@media screen and (min-width: 1500) {
  .contactUs_containerContactUs__3H0Hr{
    background: #eefafa ;
    padding-top: 0px;
  }
}
/* Flexible iFrame (for google maps)*/

.MapContainer_FlexibleContainer__GDCv5 {
    position: relative;
    padding-bottom: 95%;
    height: 0;
    overflow: hidden;
    display: block;
}



@media screen and (min-width: 768px) {
    .MapContainer_FlexibleContainer__GDCv5 {
        padding-bottom: 30%;
    }
}

